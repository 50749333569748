// src/firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCRaMkQpyVjeI1wJXZCz-apUhhB6rC8RXY",
  authDomain: "temoc-4be1a.firebaseapp.com",
  databaseURL: "https://temoc-4be1a-default-rtdb.firebaseio.com",
  projectId: "temoc-4be1a",
  storageBucket: "temoc-4be1a.firebasestorage.app",
  messagingSenderId: "1035574814935",
  appId: "1:1035574814935:web:b8a24348ef3b2d3fe86892"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app);
