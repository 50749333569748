// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/index.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f9;
}

h1, h2 {
  color: #333;
}

button {
  font-size: 1rem;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,8BAA8B;EAC9B,SAAS;EACT,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":["/* src/index.css */\nbody {\n  font-family: Arial, sans-serif;\n  margin: 0;\n  padding: 0;\n  background-color: #f4f4f9;\n}\n\nh1, h2 {\n  color: #333;\n}\n\nbutton {\n  font-size: 1rem;\n  cursor: pointer;\n}\n\na {\n  text-decoration: none;\n  color: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
