// MainPage.js

import React from 'react';
import { Link } from 'react-router-dom';

const mainPageStyles = {
  /* 
    Outer container for entire page 
    - Full viewport height
    - Flex layout to pin footer at bottom
  */
  mainPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    margin: 0,
    padding: 0,
    paddingTop: '30px',
    backgroundColor: '#000',  // Black background
    color: '#fff',            // White text
    fontFamily: 'Arial, sans-serif',
    boxSizing: 'border-box',
  },

  /* 
    Main content area 
    - Takes up remaining space (flex: 1)
    - Scrollable if content exceeds viewport 
  */
  contentWrapper: {
    flex: 1,
    overflowY: 'auto',
    width: '100%',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    boxSizing: 'border-box',
  },

  /* Header area at top of content */
  header: {
    textAlign: 'center',
    marginBottom: '30px',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    color: '#fff',
    margin: '10px 0',
  },
  tagline: {
    fontSize: '16px',
    color: '#ccc',
  },

  /* Generic section styling */
  section: {
    marginBottom: '25px',
  },
  sectionTitle: {
    fontSize: '22px',
    margin: '10px 0',
    color: '#fff',
  },
  sectionText: {
    fontSize: '14px',
    lineHeight: '1.6',
    color: '#ccc',
  },

  /* Container for multiple links or buttons */
  buttonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginTop: '15px',
  },
  button: {
    padding: '10px 15px',
    backgroundColor: '#333',
    color: '#fff',
    textDecoration: 'none',
    borderRadius: '5px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '14px',
    transition: 'background-color 0.2s ease',
  },

  /* Footer pinned at the bottom if content is short */
  footer: {
    textAlign: 'center',
    paddingBottom: '35px',
    backgroundColor: '#111',
    fontSize: '14px',
    color: '#ccc',
    borderTop: '1px solid #444',
  },

  /* “Hidden” link for "Report a Concern" */
  hiddenLink: {
    textDecoration: 'solid',
    color: '#fff',
  },
};

function MainPage() {
  return (
    <div style={mainPageStyles.mainPageContainer}>
      {/* Main content */}
      <div style={mainPageStyles.contentWrapper}>
        {/* HEADER */}
        <header style={mainPageStyles.header}>
          <h1 style={mainPageStyles.title}>The University of Texas at Dallas</h1>
          <p style={mainPageStyles.tagline}>
            Pioneering the future with innovation and research.
          </p>
        </header>

        {/* SECTION: Welcome and News */}
        <section style={mainPageStyles.section}>
          <h2 style={mainPageStyles.sectionTitle}>Welcome to UT Dallas</h2>
          <p style={mainPageStyles.sectionText}>
            A top-ranked public university located in one of the fastest-growing 
            metropolitan regions in the U.S., UT Dallas offers more than 
            <strong> 140 academic programs</strong>, world-class research 
            facilities, and a thriving campus life.
          </p>
          <p style={mainPageStyles.sectionText}>
            With over <strong>31,000 students</strong> and <strong>132,000 alumni</strong>, 
            we are committed to excellence in education, research, and innovation.
          </p>
        </section>

        {/* SECTION: Quick Links */}
        <section style={mainPageStyles.section}>
          <h2 style={mainPageStyles.sectionTitle}>Student &amp; Faculty Resources</h2>
          <div style={mainPageStyles.buttonContainer}>
            <a
              href="https://www.utdallas.edu/galaxy/"
              target="_blank"
              rel="noopener noreferrer"
              style={mainPageStyles.button}
            >
              Galaxy Portal
            </a>
            <a
              href="https://elearning.utdallas.edu/ultra/courses/_383956_1/cl/outline"
              target="_blank"
              rel="noopener noreferrer"
              style={mainPageStyles.button}
            >
              Blackboard
            </a>
            <a
              href="https://mail.google.com"
              target="_blank"
              rel="noopener noreferrer"
              style={mainPageStyles.button}
            >
              Gmail
            </a>
            <a
              href="https://oit.utdallas.edu/"
              target="_blank"
              rel="noopener noreferrer"
              style={mainPageStyles.button}
            >
              OIT Services
            </a>
            <a
              href="https://www.utdallas.edu/library/"
              target="_blank"
              rel="noopener noreferrer"
              style={mainPageStyles.button}
            >
              Library
            </a>
            <a
              href="https://www.utdallas.edu/dining/"
              target="_blank"
              rel="noopener noreferrer"
              style={mainPageStyles.button}
            >
              Dining Services
            </a>
          </div>
        </section>

        {/* SECTION: Academics */}
        <section style={mainPageStyles.section}>
          <h2 style={mainPageStyles.sectionTitle}>Academics</h2>
          <p style={mainPageStyles.sectionText}>
            UT Dallas is home to seven schools, offering undergraduate, graduate, 
            and professional programs in diverse fields such as 
            <strong> Engineering, Business, Arts &amp; Technology, Natural Sciences, 
            and Social Sciences</strong>.
          </p>
          <ul style={mainPageStyles.sectionText}>
            <li>
              <strong>School of Engineering &amp; Computer Science</strong> – 
              Cutting-edge research in AI, robotics, and cybersecurity.
            </li>
            <li>
              <strong>Naveen Jindal School of Management</strong> – 
              A top-ranked business school in Texas.
            </li>
            <li>
              <strong>School of Arts, Technology, and Emerging Communication</strong> – 
              Bridging the gap between arts and technology.
            </li>
          </ul>
        </section>

        {/* SECTION: Student Life */}
        <section style={mainPageStyles.section}>
          <h2 style={mainPageStyles.sectionTitle}>Campus Life</h2>
          <p style={mainPageStyles.sectionText}>
            With over <strong>350 student organizations</strong>, vibrant cultural 
            events, and a diverse student body, UT Dallas provides a fulfilling 
            experience both inside and outside the classroom.
          </p>
        </section>

        {/* SECTION: Admissions & Support */}
        <section style={mainPageStyles.section}>
          <h2 style={mainPageStyles.sectionTitle}>Get Started</h2>
          <div style={mainPageStyles.buttonContainer}>
            <a
              href="https://www.utdallas.edu/admissions/"
              target="_blank"
              rel="noopener noreferrer"
              style={mainPageStyles.button}
            >
              Apply Now
            </a>
            <a
              href="https://www.utdallas.edu/financial-aid/"
              target="_blank"
              rel="noopener noreferrer"
              style={mainPageStyles.button}
            >
              Financial Aid
            </a>
            <a
              href="https://www.utdallas.edu/career-center/"
              target="_blank"
              rel="noopener noreferrer"
              style={mainPageStyles.button}
            >
              Career Services
            </a>
          </div>
        </section>
      </div>

      {/* FOOTER: pinned at bottom */}
      <footer style={mainPageStyles.footer}>
        <p>
          Need assistance? Find helpful campus resources{' '}
          <Link to="/report" style={mainPageStyles.hiddenLink}>
            here
          </Link>.
        </p>
        <p>© UTD 2025</p>
      </footer>
    </div>
  );
}

export default MainPage;
