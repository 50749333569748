import React, { useState, useEffect, useRef } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate
} from 'react-router-dom';

import { database } from './firebaseConfig';
import { ref, onValue, push, set, remove } from 'firebase/database';

import MainPage from './MainPage'; // <-- Import our separate MainPage component

/* ----------------------------
   ChatPage Component 
   (Same chat or "report a concern" page from your example)
------------------------------ */
function ChatPage() {
  const navigate = useNavigate();

  // State for role, messages, etc.
  const [role, setRole] = useState('');
  const [message, setMessage] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [messages, setMessages] = useState([]);
  const [lastSentTimestamp, setLastSentTimestamp] = useState(null);
  const [viewedImages, setViewedImages] = useState({});
  const chatBoxRef = useRef(null);
  const inactivityTimerRef = useRef(null); // Timer ref for inactivity

  // Choose role
  const selectRole = (selectedRole) => setRole(selectedRole);

  // Handle text message send
  const sendMessage = () => {
    if (message.trim()) {
      const chatMessage = {
        sender: role,
        text: message,
        timestamp: Date.now(),
        type: 'text',
      };
      const messagesRef = ref(database, 'messages');
      push(messagesRef, chatMessage);
      setMessage('');
    }
  };

  // Handle image selection
  const handleImageSelection = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  // Convert image to Base64 and send
  const sendImage = async () => {
    if (!imageFile) return;
    try {
      const base64Data = await convertToBase64(imageFile);
      const chatMessage = {
        sender: role,
        imageData: base64Data,
        timestamp: Date.now(),
        type: 'image',
      };
      const messagesRef = ref(database, 'messages');
      push(messagesRef, chatMessage);
      setImageFile(null);
    } catch (error) {
      console.error('Error converting image:', error);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Listen for Enter key for text messages
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  // Realtime listener to fetch messages
  useEffect(() => {
    const messagesRef = ref(database, 'messages');

    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Convert object to array
        let messagesData = Object.entries(data).map(([key, value]) => ({
          id: key,
          ...value,
        }));
        // Sort by timestamp
        messagesData.sort((a, b) => a.timestamp - b.timestamp);

        // Limit to the latest 50 messages
        if (messagesData.length > 50) {
          messagesData = messagesData.slice(-10); // keep the latest 10
          // Overwrite DB with those 10
          set(messagesRef, messagesData.map(({ id, ...rest }) => rest));
        }

        setMessages(messagesData);
      } else {
        setMessages([]);
      }
    });

    return () => unsubscribe();
  }, []);

  // Scroll to bottom & track timestamp
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      setLastSentTimestamp(lastMessage.timestamp);
    } else {
      setLastSentTimestamp(null);
    }
  }, [messages]);

  // Inactivity timer: After 30 seconds of no activity, go back to MainPage.
  useEffect(() => {
    const resetInactivityTimer = () => {
      // Clear any existing timer
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
      // Set a new 30-second timer
      inactivityTimerRef.current = setTimeout(() => {
        navigate('/');
      }, 45000);
    };

    // List of events that denote user activity.
    const events = ['mousemove', 'keydown', 'click', 'scroll'];
    // Attach event listeners for each event to reset the timer.
    events.forEach((event) =>
      window.addEventListener(event, resetInactivityTimer)
    );

    // Set the initial timer
    resetInactivityTimer();

    // Clean up event listeners and clear timer on unmount.
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, resetInactivityTimer)
      );
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
    };
  }, [navigate]);

  // Clear chat
  const clearMessages = () => {
    const messagesRef = ref(database, 'messages');
    set(messagesRef, null);
    setMessages([]);
    setLastSentTimestamp(null);
  };

  // Format timestamp
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString();
  };

  // Handle ephemeral image view
  const handleViewImage = (msg) => {
    setViewedImages((prev) => ({ ...prev, [msg.id]: true }));
    setTimeout(() => {
      const messageRef = ref(database, `messages/${msg.id}`);
      remove(messageRef).catch((error) =>
        console.error('Error removing image:', error)
      );
    }, 10000);
  };

  // If no role selected, prompt user
  if (!role) {
    return (
      <div style={chatStyles.roleContainer}>
        <div>
          <h1>Select Your Role</h1>
          <button onClick={() => selectRole('Me')} style={chatStyles.button}>
            Me
          </button>
          <button onClick={() => selectRole('You')} style={chatStyles.button}>
            You
          </button>
          <button style={chatStyles.button} onClick={() => navigate('/')}>
            Back to Main
          </button>
        </div>
      </div>
    );
  }

  return (
    <div style={chatStyles.container}>
      <div style={chatStyles.chatBox} ref={chatBoxRef}>
        {messages.map((msg) => {
          const isMyMessage = msg.sender === role;
          return (
            <div
              key={msg.id}
              style={{
                ...chatStyles.messageRow,
                justifyContent: isMyMessage ? 'flex-end' : 'flex-start',
              }}
            >
              <div
                style={{
                  ...chatStyles.messageBubble,
                  backgroundColor: isMyMessage ? '#333' : '#1E1E1E',
                  borderTopRightRadius: isMyMessage ? '0' : '15px',
                  borderTopLeftRadius: isMyMessage ? '15px' : '0',
                  textAlign: isMyMessage ? 'right' : 'left',
                }}
              >
                {msg.type === 'text' && <span>{msg.text}</span>}

                {msg.type === 'image' && msg.imageData && (
                  <>
                    {!viewedImages[msg.id] ? (
                      <button
                        onClick={() => handleViewImage(msg)}
                        style={chatStyles.viewImageButton}
                      >
                        View Image
                      </button>
                    ) : (
                      <img
                        src={msg.imageData}
                        alt="Sent"
                        style={chatStyles.chatImage}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {/* Text Input & Send */}
      <div style={chatStyles.inputContainer}>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type a message"
          style={chatStyles.input}
        />
        <button onClick={sendMessage} style={chatStyles.sendButton}>
          Send
        </button>
      </div>

      {/* Image Input & Send */}
      <div style={chatStyles.inputContainer}>
        <input
          type="file"
          onChange={handleImageSelection}
          style={chatStyles.fileInput}
        />
        <button onClick={sendImage} style={chatStyles.sendButton}>
          Send Image
        </button>
      </div>

      {/* Clear Chat */}
      <button onClick={clearMessages} style={chatStyles.clearButton}>
        Clear Chat
      </button>

      {/* Last message timestamp */}
      {lastSentTimestamp && (
        <div style={chatStyles.timestamp}>
          Last message sent at {formatTime(lastSentTimestamp)}
        </div>
      )}

      <button style={chatStyles.clearButton} onClick={() => navigate('/')}>
        Back to Main
      </button>
    </div>
  );
}

/* 
  Styles for the Chat / Report a Concern page.
  You can place them in a separate file or keep
  them here, as needed.
*/
const chatStyles = {
  roleContainer: {
    paddingTop: 'calc(80px + env(safe-area-inset-top))',
    backgroundColor: '#000',
    color: '#FFF',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto', // Enables scrolling if content overflows
  },
  container: {
    padding: 'calc(20px + env(safe-area-inset-top)) 20px 10px 20px',
    backgroundColor: '#000',
    color: '#FFF',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto', // Enables scrolling if content overflows
  },
  chatBox: {
    border: '1px solid #444',
    marginTop: '50px',
    padding: '10px',
    marginBottom: '10px',
    height: '60vh',
    overflowY: 'auto',
    backgroundColor: '#111',
    borderRadius: '5px',
    width: '100%',
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
  },
  messageRow: {
    display: 'flex',
    marginBottom: '8px',
    width: '100%',
  },
  messageBubble: {
    color: '#FFF',
    padding: '10px',
    borderRadius: '15px',
    maxWidth: '70%',
    wordWrap: 'break-word',
  },
  chatImage: {
    maxWidth: '200px',
    maxHeight: '200px',
    display: 'block',
    marginTop: '5px',
    border: '1px solid #555',
    borderRadius: '3px',
  },
  viewImageButton: {
    backgroundColor: '#2E2E2E',
    color: '#FFF',
    padding: '6px 10px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '600px',
    marginBottom: '10px',
  },
  fileInput: {
    color: '#FFF',
    backgroundColor: '#222',
    border: '1px solid #444',
    borderRadius: '5px',
    padding: '6px',
    flex: 1,
  },
  input: {
    flex: 1,
    padding: '10px',
    backgroundColor: '#222',
    color: '#FFF',
    border: '1px solid #444',
    borderRadius: '5px',
  },
  sendButton: {
    padding: '10px',
    marginLeft: '10px',
    backgroundColor: '#555',
    color: '#FFF',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    minWidth: '80px',
  },
  clearButton: {
    padding: '10px',
    backgroundColor: '#444',
    color: '#FFF',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    width: '100%',
    maxWidth: '600px',
    marginBottom: '10px',
  },
  timestamp: {
    color: '#888',
    fontSize: '12px',
    marginBottom: '10px',
  },
  button: {
    margin: '10px',
    padding: '10px',
    backgroundColor: '#444',
    color: '#FFF',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    width: '120px',
  },
};

/* -----------------------------------------------
   The main App Component that handles routing
-------------------------------------------------- */
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/report" element={<ChatPage />} />
      </Routes>
    </Router>
  );
}

export default App;
