import React, { useEffect, useState } from 'react';
import { signOut } from 'firebase/auth';
import { auth, db, storage } from '../firebase';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';

function Dashboard() {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Real-time listener for Firestore
    const q = query(collection(db, 'logs'), orderBy('timestamp', 'desc'), limit(1000));

    const unsubscribe = onSnapshot(
      q,
      async (snapshot) => {
        const newLogs = [];
        const promises = snapshot.docs.map(async (doc) => {
          const data = doc.data();
          try {
            const url = await getDownloadURL(ref(storage, data.image_path));
            return {
              id: doc.id, // Unique ID for tracking
              timestamp: data.timestamp,
              imageURL: url,
            };
          } catch (err) {
            console.error(`Failed to fetch image for ${data.image_path}:`, err);
            return {
              id: doc.id,
              timestamp: data.timestamp,
              imageURL: null, // Handle missing image
            };
          }
        });

        try {
          const resolvedLogs = await Promise.all(promises);
          newLogs.push(...resolvedLogs);
        } catch (err) {
          console.error("Error resolving logs:", err);
          setError("Failed to fetch logs. Please try again later.");
        }

        setLogs(newLogs); // Update logs dynamically
        setLoading(false);
      },
      (err) => {
        console.error("Error listening for Firestore updates:", err);
        setError("Failed to fetch logs. Please try again later.");
        setLoading(false);
      }
    );

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth);
  };

  const styles = {
    container: {
      margin: '2rem auto',
      maxWidth: '800px',
      padding: '1rem',
      backgroundColor: '#f9f9f9',
      border: '1px solid #ddd',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '1rem',
    },
    title: {
      margin: 0,
      color: '#333',
    },
    logoutButton: {
      padding: '0.5rem 1rem',
      backgroundColor: '#dc3545',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    imageGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
      gap: '1rem',
    },
    imageCard: {
      textAlign: 'center',
      backgroundColor: '#fff',
      padding: '1rem',
      border: '1px solid #ddd',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    image: {
      maxWidth: '100%',
      borderRadius: '4px',
    },
    timestamp: {
      marginTop: '0.5rem',
      color: '#666',
      fontSize: '0.9rem',
    },
  };

  if (loading) {
    return <div style={styles.container}>Loading...</div>;
  }

  if (error) {
    return <div style={styles.container}>Error: {error}</div>;
  }

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1 style={styles.title}>Dashboard</h1>
        <button style={styles.logoutButton} onClick={handleLogout}>
          Logout
        </button>
      </div>
      <h2 style={{ color: '#555' }}>Recent Captures</h2>
      <div style={styles.imageGrid}>
        {logs.map((log) => {
          const date = new Date(log.timestamp * 1000).toLocaleString();
          return (
            <div key={log.id} style={styles.imageCard}>
              {log.imageURL ? (
                <img src={log.imageURL} alt="capture" style={styles.image} />
              ) : (
                <p style={{ color: 'red' }}>Image not found</p>
              )}
              <p style={styles.timestamp}>{date}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Dashboard;
