import React, { useEffect, useState } from 'react';
import { signOut } from 'firebase/auth';
import { auth, db, storage } from '../firebase';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  where,
  limit,
  deleteDoc,
  doc,
  getDocs,
} from 'firebase/firestore';
import {
  getDownloadURL,
  ref,
  deleteObject,
  listAll,
} from 'firebase/storage';

function Dashboard() {
  const [logs, setLogs] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [startTime, setStartTime] = useState('00:00');
  const [endTime, setEndTime] = useState('23:59');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State for the arming feature
  const [armed, setArmed] = useState(false);
  const [armedTime, setArmedTime] = useState(null);
  const [firstMotion, setFirstMotion] = useState(null);
  const [lastMotion, setLastMotion] = useState(null);

  // Fetch unique dates from Firestore logs
  useEffect(() => {
    const fetchDates = async () => {
      const q = query(collection(db, 'logs'), orderBy('timestamp', 'desc'));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const uniqueDates = new Set();
        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          const date = new Date(data.timestamp * 1000)
            .toISOString()
            .split('T')[0];
          uniqueDates.add(date);
        });
        const dateArray = [...uniqueDates];
        setDates(dateArray);
        setSelectedDate(dateArray[0] || '');
        setLoading(false);
      });
      return () => unsubscribe();
    };

    fetchDates();
  }, []);

  // Listen for logs based on filter criteria
  useEffect(() => {
    if (!selectedDate) return;

    setLoading(true);
    const startTimestamp = new Date(`${selectedDate}T${startTime}:00`).getTime() / 1000;
    const endTimestamp = new Date(`${selectedDate}T${endTime}:00`).getTime() / 1000;

    const q = query(
      collection(db, 'logs'),
      where('timestamp', '>=', startTimestamp),
      where('timestamp', '<=', endTimestamp),
      orderBy('timestamp', 'desc'),
      limit(1000)
    );

    const unsubscribe = onSnapshot(
      q,
      async (snapshot) => {
        const promises = snapshot.docs.map(async (docSnap) => {
          const data = docSnap.data();
          try {
            const url = await getDownloadURL(ref(storage, data.image_path));
            return {
              id: docSnap.id,
              timestamp: data.timestamp,
              imageURL: url,
              image_path: data.image_path, // used for deletion
              status: data.status || 'green',
            };
          } catch (err) {
            console.error(`Failed to fetch image for ${data.image_path}:`, err);
            return {
              id: docSnap.id,
              timestamp: data.timestamp,
              imageURL: null,
              image_path: data.image_path,
              status: data.status || 'green',
            };
          }
        });

        try {
          const resolvedLogs = await Promise.all(promises);
          setLogs(resolvedLogs);
        } catch (err) {
          console.error('Error resolving logs:', err);
          setError('Failed to fetch logs. Please try again later.');
        }
        setLoading(false);
      },
      (err) => {
        console.error('Error listening for Firestore updates:', err);
        setError('Failed to fetch logs. Please try again later.');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [selectedDate, startTime, endTime]);

  // Check for red (motion) events when the system is armed
  useEffect(() => {
    if (armed) {
      const redLogs = logs.filter((log) => log.status === 'red');
      if (redLogs.length > 0) {
        const earliest = redLogs.reduce((prev, curr) =>
          curr.timestamp < prev.timestamp ? curr : prev
        );
        const latest = redLogs.reduce((prev, curr) =>
          curr.timestamp > prev.timestamp ? curr : prev
        );
        if (!firstMotion || earliest.timestamp < firstMotion) {
          setFirstMotion(earliest.timestamp);
        }
        setLastMotion(latest.timestamp);
      }
    }
  }, [logs, armed]);

  const handleLogout = () => {
    signOut(auth);
  };

  // Toggle armed state and record the armed time
  const toggleArmed = () => {
    if (armed) {
      setArmed(false);
      setArmedTime(null);
      setFirstMotion(null);
      setLastMotion(null);
    } else {
      setArmed(true);
      setArmedTime(Math.floor(Date.now() / 1000));
      setFirstMotion(null);
      setLastMotion(null);
    }
  };

  // When armed, only show logs after the system was armed
  const filteredLogs =
    armed && armedTime ? logs.filter((log) => log.timestamp >= armedTime) : logs;

  // Clear all images from Firebase Storage and delete all logs from Firestore
  const handleClearStorage = async () => {
    const confirmed = window.confirm(
      'Are you sure you want to clear all images from storage and delete all logs from the database?'
    );
    if (!confirmed) return;

    try {
      // Delete images from Firebase Storage (adjust folder if needed)
      const imagesRef = ref(storage, 'images');
      const listResult = await listAll(imagesRef);
      const deletionPromises = listResult.items.map((itemRef) =>
        deleteObject(itemRef)
      );
      await Promise.all(deletionPromises);

      // Delete all logs from Firestore
      const logsCollectionRef = collection(db, 'logs');
      const snapshot = await getDocs(logsCollectionRef);
      const deletePromises = snapshot.docs.map((docSnap) =>
        deleteDoc(doc(db, 'logs', docSnap.id))
      );
      await Promise.all(deletePromises);

      // Clear local logs state
      setLogs([]);
      alert('All images and logs have been cleared and the system has been reset.');
    } catch (err) {
      console.error('Error clearing storage and logs:', err);
      alert('Error clearing storage and logs. Please check the console for details.');
    }
  };

  const styles = {
    container: {
      margin: '2rem auto',
      maxWidth: '800px',
      padding: '1rem',
      backgroundColor: '#fff',
      border: '1px solid #000',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '1rem',
    },
    title: {
      margin: 0,
      color: '#000',
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    buttonGroup: {
      display: 'flex',
      alignItems: 'center',
    },
    logoutButton: {
      padding: '0.5rem 1rem',
      backgroundColor: '#000',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
      marginLeft: '0.5rem',
    },
    armButton: {
      padding: '0.5rem 1rem',
      backgroundColor: armed ? '#c82333' : '#28a745',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
    },
    clearButton: {
      padding: '0.5rem 1rem',
      backgroundColor: '#555',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
      marginLeft: '0.5rem',
    },
    statusIndicator: {
      textAlign: 'center',
      marginBottom: '1rem',
      fontSize: '1rem',
    },
    filterContainer: {
      marginBottom: '1rem',
      padding: '1rem',
      backgroundColor: '#f2f2f2',
      border: '1px solid #000',
      borderRadius: '8px',
    },
    select: {
      padding: '0.5rem',
      borderRadius: '4px',
      border: '1px solid #000',
      fontSize: '1rem',
      cursor: 'pointer',
      backgroundColor: '#fff',
      color: '#000',
    },
    input: {
      padding: '0.5rem',
      borderRadius: '4px',
      border: '1px solid #000',
      fontSize: '1rem',
      backgroundColor: '#fff',
      color: '#000',
    },
    imageGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
      gap: '1rem',
    },
    imageCard: {
      textAlign: 'center',
      backgroundColor: '#fff',
      padding: '1rem',
      border: '1px solid #000',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    },
    image: {
      maxWidth: '100%',
      borderRadius: '4px',
      transition: 'transform 0.2s ease',
    },
    timestamp: {
      marginTop: '0.5rem',
      color: '#000',
      fontSize: '0.9rem',
    },
  };

  if (loading) {
    return <div style={styles.container}>Loading...</div>;
  }

  if (error) {
    return <div style={styles.container}>Error: {error}</div>;
  }

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1 style={styles.title}>Dashboard</h1>
        <div style={styles.buttonGroup}>
          <button
            style={styles.armButton}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = armed
                ? '#a71d2a'
                : '#218838')
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = armed
                ? '#c82333'
                : '#28a745')
            }
            onClick={toggleArmed}
          >
            {armed ? 'Disarm' : 'Arm'}
          </button>
          <button
            style={styles.logoutButton}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = '#333')
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = '#000')
            }
            onClick={handleLogout}
          >
            Logout
          </button>
          <button
            style={styles.clearButton}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = '#777')
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = '#555')
            }
            onClick={handleClearStorage}
          >
            Clear Images
          </button>
        </div>
      </div>
      <div style={styles.statusIndicator}>
        {armed ? (
          <>
            {lastMotion ? (
              <p style={{ color: 'red' }}>
                Motion has been detected at{' '}
                {new Date(lastMotion * 1000).toLocaleTimeString()}.
              </p>
            ) : (
              <p style={{ color: 'green' }}>
                System is armed since{' '}
                {armedTime
                  ? new Date(armedTime * 1000).toLocaleTimeString()
                  : 'N/A'}
                .
              </p>
            )}
            {firstMotion && (
              <p style={{ color: '#000' }}>
                Motion first detected at{' '}
                {new Date(firstMotion * 1000).toLocaleString()}.
              </p>
            )}
          </>
        ) : (
          <p style={{ color: 'gray' }}>System is disarmed.</p>
        )}
      </div>
      <div style={styles.filterContainer} className="filter-container">
        <label>
          Select Date:
          <select
            style={styles.select}
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          >
            {dates.map((date) => (
              <option key={date} value={date}>
                {date}
              </option>
            ))}
          </select>
        </label>
        <label>
          Start Time:
          <input
            style={styles.input}
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
          />
        </label>
        <label>
          End Time:
          <input
            style={styles.input}
            type="time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
          />
        </label>
      </div>
      <div style={styles.imageGrid}>
        {filteredLogs.map((log) => {
          const date = new Date(log.timestamp * 1000).toLocaleString();
          return (
            <div
              key={log.id}
              style={{
                ...styles.imageCard,
                transform: 'translateY(0)',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'translateY(-5px)';
                e.currentTarget.style.boxShadow =
                  '0 4px 8px rgba(0, 0, 0, 0.2)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'translateY(0)';
                e.currentTarget.style.boxShadow =
                  '0 2px 4px rgba(0, 0, 0, 0.1)';
              }}
            >
              {log.imageURL ? (
                <img
                  src={log.imageURL}
                  alt="capture"
                  style={styles.image}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.transform = 'scale(1.05)')
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.transform = 'scale(1)')
                  }
                />
              ) : (
                <p style={{ color: 'red' }}>Image not found</p>
              )}
              <p style={styles.timestamp}>{date}</p>
            </div>
          );
        })}
      </div>
      <style>{`
        @media (min-width: 600px) {
          .filter-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .filter-container label {
            flex: 1;
            margin-right: 1rem;
          }
          .filter-container label:last-child {
            margin-right: 0;
          }
        }
        @media (max-width: 600px) {
          .filter-container {
            display: block;
          }
          .filter-container label {
            display: block;
            margin-bottom: 1rem;
          }
        }
      `}</style>
    </div>
  );
}

export default Dashboard;
