// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* global.css */
body, html {
    margin: 0;
    padding: 0;
    background-color: #000; /* Black background */
    color: #FFF; /* Default text color */
    height: 100%;
    overflow: hidden;
}

#root {
    height: 100%; /* Ensure the React app takes the full height */
    display: flex;
    justify-content: center;
    align-items: center;
}

`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,SAAS;IACT,UAAU;IACV,sBAAsB,EAAE,qBAAqB;IAC7C,WAAW,EAAE,uBAAuB;IACpC,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY,EAAE,+CAA+C;IAC7D,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":["/* global.css */\nbody, html {\n    margin: 0;\n    padding: 0;\n    background-color: #000; /* Black background */\n    color: #FFF; /* Default text color */\n    height: 100%;\n    overflow: hidden;\n}\n\n#root {\n    height: 100%; /* Ensure the React app takes the full height */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
