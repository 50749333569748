// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBmGgwYOA7SzgpiWlqAXybUr78wzbrF6sk",
    authDomain: "room-sec-6d556.firebaseapp.com",
    projectId: "room-sec-6d556",
    storageBucket: "room-sec-6d556.firebasestorage.app",
    messagingSenderId: "91991963131",
    appId: "1:91991963131:web:f38d0c6a844de6de4f075e"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
